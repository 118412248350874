import React, { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { Form } from '../../../components';
import LocationAndRadiusFilter from '../LocationAndRadiusFilter';
import { useLocation } from 'react-router-dom';
import css from './LandingSearchForm.module.css';

const LandingSearchFormComponent = props => {
  const { viewport, onSubmit } = props;
  return (
    <FinalForm
      {...props}
      onSubmit={onSubmit}
      render={formRenderProps => {
        const { rootClassName, className } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        return (
          <Form className={classes} onSubmit={e => e.preventDefault()}>
            <LocationAndRadiusFilter viewport={viewport} />
          </Form>
        );
      }}
    />
  );
};

LandingSearchFormComponent.defaultProps = { rootClassName: null, className: null };

LandingSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  intl: intlShape.isRequired,
};

const LandingSearchForm = injectIntl(LandingSearchFormComponent);

export default LandingSearchForm;
